<template>
    <div class="clinical-counselors">
        <div class="clinical-counselors-header">
            <h3>Service Coordinator</h3>
        </div>
    <div v-if="!loading || flag" class="clinical-counselors-list">
        <div >
            <div class="primary-counselor">
                <div class="flex bottom flex-row align-items-end gap-1" >
                    <!-- If current logged in user has admin permission -->
                    <div class="primary-counselor-dropdown">
                        <CounselorDropDown 
                            class="flex-1" 
                            v-model="primaryCounselor.id" 
                            :options="counselorOptions"
                            label="Primary Counselor" 
                            placeholder="Select a primary counselor" 
                            :disableEmpty="true"
                            :disabled="!isUserAdminOrSuperAdmin"
                            @change="(value) => { handlePrimaryCounselorEvent(value, primaryCounselor.id) }" 
                        />
                    </div>
                    <!-- If current logged in user has other permission -->
                    <!-- <div v-if="!isUserAdminOrSuperAdmin" class="primary-counselor-as-text flex bottom">
                    <h3> <b>Primary Counselor : </b> {{ primaryCounselor.first_name + " " + primaryCounselor.last_name }} </h3>
                    </div> -->
                    <button class="primary text-button  inline-block" @click="addCounselor($event)">
                        <span class="rounded-add-custom material-icons-outlined">add</span>
                        <span class="additional-counselor-text">Add additional counselor</span>
                    </button>
                </div>
            </div>
            <!-- If current logged in user has admin permission or logged in user is primary counselor -->
            <div class="secondary-counselors" v-if="secondary_counselors.length>0">
                    <label >Secondary Counselors</label>
                <div class="secondary-counselor-item"  v-for="(counselor, index) in secondary_counselors" :key="counselor.id">
                    <div class="secondary-counselor-dropdown" >
                            <CounselorDropDown 
                                class="w-100" 
                                v-model="counselor.id" 
                                :options="counselorOptions" 
                                label="" 
                                placeholder="Select Secondary counselor" 
                                :disableEmpty="true"
                                @change="(value) => { handleSecondaryCounselorEvent(value, counselor.id, index) }"
                                :disableLabel="true" 
                                :disabled="counselor.id === logged_in_user_id && isUserAdminOrSuperAdmin"
                            />
                    </div>
                    <div v-if="counselor.id !== logged_in_user_id || isUserAdminOrSuperAdmin" class="" @click="removeCounselor('secondary', index, counselor.id)">
                        <ejs-tooltip class="tooltipcontainer" content="Delete" target="#counselor_delete"
                                cssClass="customtooltip" position="top">
                        <span id="counselor_delete" class="material-symbols-outlined">delete</span>
                        </ejs-tooltip>
                    </div>
                </div>
            </div>

        </div>
        <button class="primary top-10" type="button" @click="saveCounselors()" :disabled="!isCounselorsChanges">Save</button>
    </div>
    <Loading v-if="loading" />
    </div>
</template>

<script>
import CounselorDropDown from '@/components/general/validatedInputs/CounselorDropDown.vue';
import { clients, companies } from '@/util/apiRequests';
import Loading from '@/components/general/loading/loading.vue';
import { CounselorsErrorMessages } from '@/util/globalConstants'
import { Roles } from '@/util/globalConstants'
import { API } from 'aws-amplify';
import { getSeniors } from './../../../util/graphql/queries';
import { memberProfileUpdateNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';

export default {
    components: {
        CounselorDropDown,
        Loading
    },
    props: {
        client_id: {},
        logged_in_user: {}
    },
    data() {
        return {
            secondary_counselors: [],
            primaryCounselor: {},
            cliniciansListRaw: [],
            showPrimary: true,
            showSecondary: false,
            showTertiary: false,
            counselorOptions: [],
            counselorOptionsSecondary: [],
            counselorOptionsOriginal: [],
            loading: true,
            logged_in_user_id: undefined,
            role_id: undefined,
            Roles,
            flag: false,
            isCounselorsChanges: false,
        };
    },
    computed:{
        isUserAdminOrSuperAdmin(){
            return [Roles.ADMIN, Roles.SUPER_ADMIN].includes(this.role_id);
        }
    },
    methods: {
        getValidCounselorObject() {
            if (!this.primaryCounselor?.id) {
                return { valid: false, error: CounselorsErrorMessages.SELECT_PRIMARY_COUNSELOR };
            }
            let secondary_counselors_Ids=this.secondary_counselors.map(counselor=>counselor.id)
            if(secondary_counselors_Ids.includes(null))
                return { valid: false, error: CounselorsErrorMessages.SECONDARY_COUNSELOR_FIELD_CANT_BE_EMPTY };
                let requestObject = {
                    counselors: {
                        primary_counselor: this.primaryCounselor.id,
                        secondary_counselors:secondary_counselors_Ids
                    }
                }
                return { valid: true, requestObject, }
        },
        handlePrimaryCounselorEvent(value, prev) {
            const newPrimaryCounselor = this.cliniciansListRaw.find((clinician) => clinician.id == value)
            this.primaryCounselor = { ...this.primaryCounselor, ...newPrimaryCounselor, isModified: true }
            this.counselorOptions.forEach(function (obj) {
                if (obj.value == value)
                    obj.disabled = true
                if (obj.value == prev) {
                    obj.disabled = false
                }
            })
            this.isCounselorsChanges = true;
        },
        handleSecondaryCounselorEvent(value, prev, index) {
            let tempCounSelor = this.cliniciansListRaw.find((clinician) => clinician.id == value)
            this.secondary_counselors.splice(index, 1, tempCounSelor)
            this.counselorOptions.forEach(function (obj) {
                if (obj.value == value)
                    obj.disabled = true
                if (obj.value == prev) {
                    obj.disabled = false
                }
            })
            this.counselorOptions = JSON.parse(JSON.stringify(this.counselorOptions))
            this.isCounselorsChanges = true;
        },
        removeCounselor(type, index, value) {
            if (type === 'secondary')
                this.secondary_counselors.splice(index, 1,)
            this.counselorOptions.forEach(function (obj) {
                if (obj.value == value)
                    obj.disabled = false
            })
            this.counselorOptions = JSON.parse(JSON.stringify(this.counselorOptions))
            this.isCounselorsChanges = true;
        },
        async getClientCounselors() {
            try {
                let result = await this.$api.get(clients.getCounselors(this.client_id));
                this.primaryCounselor = result.data.counselors?.primary_counselor
                this.secondary_counselors = result.data.counselors?.secondary_counselors
                this.counselorOptions = this.counselorOptions.filter(counselor =>  
                    result.data.counselors?.primary_counselor!==counselor.id 
                    && !result.data.counselors?.secondary_counselors.some(c => c.id===counselor.id)
                ) 
            } catch (err) {
                this.$toasted.error(CounselorsErrorMessages.COULDNT_RETRIEVE_CLIENT_COUNSELORS);
            }
        },
        async getCounselorOptions() {
            try {
                let result = await this.$api.get(companies.getClinicians(this.$store.state.user.company_id));
                this.cliniciansListRaw = result.data;
                this.counselorOptions = result.data.map((element) => {
                    return { text: `${element?.first_name} ${element?.last_name}`, value: element.id };
                });
                this.counselorOptionsOriginal = this.counselorOptions;
                this.counselorOptions.forEach(function (obj) {
                    obj.disabled = false;
                })
                this.counselorOptions = this.counselorOptions.map( el => {
                    if( el.value === this.primaryCounselor.id ) {
                        el.disabled = true;
                    }
                    if( ( this.secondary_counselors.findIndex( el1 => el1.id === el.value ) ) > -1 ) {
                        el.disabled = true;
                    }
                    return el;
                } )
                this.counselorOptionsSecondary = JSON.parse(JSON.stringify(this.counselorOptions))
            } catch (err) {
                this.$toasted.error(CounselorsErrorMessages.COULDNT_RETRIEVE_COUNSELORS);
            }
        },
        async sendMemberProfileUpdateNotification() {
            try {
                await memberProfileUpdateNotification(this.$store.state?.clientOverview?.clientData?.email)
            } catch (err) {
                return this.$toasted.error('Failed to send notification to member');
            }
        },
        async saveCounselors() {
            const counselors = this.getValidCounselorObject()
            if (counselors.valid) {
                this.flag = true;
                this.loading = true;
                try {
                    let response = await this.$api.post(clients.saveCounselors(this.client_id), counselors.requestObject);
                    if (response.status == 200) {
                        this.isCounselorsChanges = false;
                        await this.sendMemberProfileUpdateNotification();
                        const {data:{getSeniors:membersList}}=await API.graphql({query: getSeniors});
                        await this.$store.commit('persistRelatedClients',membersList);
                        this.$toasted.success(response.data?.message);
                    } else if (response.status == 500) {
                        this.$toasted.error(response.data?.message);
                    }
                } catch (error) {
                    this.$toasted.error(CounselorsErrorMessages.COUNSELORS_SAVE_FAILURE_MESSAGE);
                }
                finally{
                    this.flag = false
                    this.loading = false;
                }
            }
            else {
                this.$toasted.error(counselors.error);
            }
        },
        calibrateMenu(newValue, oldValue) {
            if (!this.loading) {
                let optionToAdd = null;
                if (oldValue !== null && oldValue) {
                    optionToAdd = this.counselorOptionsOriginal.find(counselor => counselor.value == oldValue);

                }
                this.counselorOptions = this.counselorOptions.map(function (obj) {
                    if (optionToAdd?.value == obj?.value) {
                        obj.disabled = false;
                    }
                    if (obj?.value == newValue) { obj.disabled = true }
                    return obj;
                })
            }
        },
        remove(type) {
            switch (type) {
                case 'primary':
                    if (this.counselors.secondary_counselor && !this.counselors.tertiary_counselor) {
                        this.counselors.primary_counselor = this.counselors.secondary_counselor
                        this.counselors.secondary_counselor = '';
                        if (this.showTertiary == true) {
                            this.showSecondary = true;
                            this.showTertiary = false;
                        } else {
                            this.showSecondary = false;
                        }
                    }
                    else if (this.counselors.secondary_counselor && this.counselors.tertiary_counselor) {
                        this.counselors.primary_counselor = this.counselors.secondary_counselor
                        this.counselors.secondary_counselor = this.counselors.tertiary_counselor;
                        this.counselors.tertiary_counselor = '';
                        this.showTertiary = false;
                    }
                    else {
                        if (this.showSecondary) {
                            this.showSecondary = false;
                        }
                        this.counselors.primary_counselor = '';
                    }

                    break;
                case 'secondary':
                    if (this.counselors.tertiary_counselor) {
                        this.counselors.secondary_counselor = this.counselors.tertiary_counselor;
                        this.showTertiary = false;
                        this.counselors.tertiary_counselor = '';

                        // this.showTertiary = false;

                    }
                    else if (!this.counselors.tertiary_counselor && this.showTertiary == true) {
                        this.showTertiary = false;
                        this.counselors.secondary_counselor = '';
                    }
                    else {

                        this.counselors.secondary_counselor = '';
                        this.showSecondary = false;

                        // this.showTertiary = false;

                    }
                    break;
                case 'tertiary':

                    this.counselors.tertiary_counselor = '';
                    this.showTertiary = false;
                    // this.calibrateMenu('')
                    break;
                default:
                    break;
            }
        },
        addCounselor(e) {
            e.preventDefault()
            if (!this.primaryCounselor.id) {
                this.$toasted.error(CounselorsErrorMessages.SELECT_PRIMARY_COUNSELOR);
                return;
            }
            if( this.counselorOptions.length - 1 <= this.secondary_counselors.length ) {
                this.$toasted.error('You can add maximum '+ this.secondary_counselors.length +' counselors !');
                return;
            }
            if( (this.secondary_counselors.filter( el => el.id === null )).length > 0 ) {
                this.$toasted.error('Please choose above counselor !');
                return;
            }
            this.secondary_counselors.push({
                "id": null,
                "first_name": "",
                "last_name": "",
            })
            this.isCounselorsChanges = true;
        }
    },
    async created() {
        this.logged_in_user_id = this.logged_in_user.id;
        this.role_id = this.logged_in_user.role_id;
        this.secondary_counselors = this.secondary_counselors.map((counselor) => {
            return counselor
        })
        await Promise.all([this.getCounselorOptions(), this.getClientCounselors()]);
        this.loading = false;
        this.counselorOptionsSecondary = this.counselorOptions
    }
};
</script>

<style>
    .w-100 {
        width: 100%;
    }
</style>
