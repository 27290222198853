<template>
    <div class="client-manager" v-if="this.$store.state.clientOverview.loaded">
        <div class="member-profile-sidebar">
            <div class="user-profile-header member-profile-header">
                <!-- <UploadOverlay/> -->
                <div class="member-profile-photo">
                    <UploadOverlay
                        :storeGetterKey="'clientOverview/getClientProfilePhoto'"
                        :storeUpdateFileKey="'clientOverview/updateField'"
                        :storeUpdateFileField="'clientProfilePhoto'"
                        :storeUpdateFileIDKey="'clientOverview/updateClientProfileId'"
                        :storeUpdateFileIDField="'client_profile_photo_file_id'"
                        :storeFileID="$store.state.clientOverview.clientData.client_profile_photo_file_id"
                        kind="client_profile_photo"
                        :client="$store.getters['clientOverview/client']"
                        :urlToUpload="`/clients/client-profile-photo?fileId=${$store.state.clientOverview.clientData.client_profile_photo_file_id}`"
                        v-if="$store.state.clientOverview.loaded"
                        :alt="''"
                    />
                    <!-- <img src="assets/px/client-profile-placeholder.png" alt=""/> -->
                </div>
                <div class="client-info" v-if="this.$store.state.clientOverview.clientData">
                    <div class="client-full-name">
                        <b>{{ $store.getters['clientOverview/clientFullName'] }}</b>
                    </div>
                    <div class="internal-id">
                        Internal ID# {{ $store.getters['clientOverview/client'].id }} -
                        {{ $store.getters['clientOverview/clientStatus'] }}
                    </div>

                    <div class="client-diseases">
                        <!-- <h3>Diseases: Diabetis</h3> -->
                    </div>
                    <div class="member-profile-sidebar-buttons flex">
                        <button
                            class="secondary top-10"
                            type="button"
                            @click="
                                () => {
                                    goToPage(sideBarTabpages[0]);
                                }
                            "
                        >
                            Medications
                        </button>
                        <button
                            class="primary top-10"
                            type="button"
                            @click="
                                () => {
                                    goToPage(sideBarTabpages[1]);
                                }
                            "
                        >
                            Message
                        </button>
                    </div>
                </div>
                <div class="member-profile-sidebar-section2">
                    <!-- live vitals will go here -->
                </div>
                <!-- <div class="client-activity">
                    <div>
                        <b>{{ $store.getters['clientOverview/clientStatus'] }}</b>
                    </div>
                    <div v-if="$store.state.clientOverview.attendance">
                        Visits attended: {{ this.$store.state.clientOverview.attendance.attended }}
                    </div>
                    <div v-if="$store.state.clientOverview.attendance">
                        Visits missed: {{ this.$store.state.clientOverview.attendance.missed }}
                    </div>
                </div> -->
            </div>
            <!-- <ClientManagerTabs :pages="tabPages" @action="goToPage" /> -->
        </div>
        <!-- <h1>Client Clinical</h1> -->
        <div class="client-manager-tabs">
            <ClientManagerTabs :pages="tabPages" @action="goToPage" />
        </div>
        <div class="client-clinical-card flex">
            <div class="flex-4 left-col margin">
                <!-- <div class="counselors-card-block"> -->
                <Counselors :client_id="clientId" :logged_in_user="loggedInUser" />
                <!-- </div> -->
                <!-- <div class="card-block"> -->
                <AttendanceHistory :client_id="clientId" />
                <!-- </div> -->
                <!-- <div class="card-block">
                    <Tags :client_id="clientId" />
                </div> -->
                <!-- <div class="card-block"> -->
                <MoodHistory :client_id="clientId" />
                <!-- </div>  -->
            </div>
            <!-- <div class="flex-6">
                <div class="card-block">
                    <ServiceCodes :client_id="clientId" />
                </div>
                <div class="card-block">
                    <ClientCustomProducts :client_id="clientId" />
                </div>
                <div class="card-block">
                    <h3 class="margin-0">Custom Services</h3>
                    <p class="margin-0 bottom-20">Add custom services and assign your fees</p>
                    <div @click="addCpt()">+ Add custom service</div>
                </div>
                <div class="card-block">
                    <DiagnosticCodes :client_id="clientId" />
                </div>
            </div> -->
        </div>
    </div>
    <Loading v-else-if="this.$store.state.clientOverview.loaded == false" />
</template>

<script>
    import ClientManagerTabs from '@/components/client-manager/ClientManagerTabs.vue';
    import Counselors from '@/components/client-manager/ClientClinical/Counselors.vue';
    // import DiagnosticCodes from '@/components/client-manager/ClientClinical/DiagnosticCodes.vue';
    import AttendanceHistory from '@/components/client-manager/ClientClinical/AttendanceHistory.vue';
    import MoodHistory from '@/components/client-manager/ClientClinical/MoodHistory.vue';
    //import Tags from '@/components/client-manager/ClientClinical/Tags.vue';
    //import ServiceCodes from '@/components/client-manager/ClientClinical/ServiceCodes.vue';
    import UploadOverlay from '@/components/general/Upload/UploadOverlay.vue';
    import Loading from '@/components/general/loading/loading.vue';
    import OfficeDropdown from '@/components/general/validatedInputs/OfficeDropdown';
    //import ClientCustomProducts from '@/components/client-manager/ClientClinical/ClientCustomProducts';

    export default {
        name: 'ClientClinical',
        components: {
            // ClientCustomProducts,
            ClientManagerTabs,
            Counselors,
            // DiagnosticCodes,
            AttendanceHistory,
            // Tags,
            // ServiceCodes,
            UploadOverlay,
            Loading,
            OfficeDropdown,
            MoodHistory,
        },
        data() {
            return {
                // clientId: this.$route.params.client_id,
                clientData: null,

                tabPages: [
                    { label: 'Overview', name: 'ClientOverview', params: { record_id: this.$route.params.client_id } },
                    {
                        label: 'Demographics',
                        name: 'ClientDemographics',
                        params: { client_id: this.$route.params.client_id },
                    },
                    // {
                    //     label: 'Insurance',
                    //     name: 'ClientInsurance',
                    //     params: { client_id: this.$route.params.client_id }
                    // },

                    // { label: 'Billing', name: 'ClientBilling', params: { client_id: this.$route.params.client_id } },
                    { label: 'Clinical', name: 'ClientClinical', params: { client_id: this.$route.params.client_id } },
                    {
                        label: 'Documents & Forms',
                        name: 'ClientDocuments',
                        params: { client_id: this.$route.params.client_id },
                    },
                    {
                        label: 'Photos',
                        name: 'ClientPhotos',
                        params: { client_id: this.$route.params.client_id },
                        // icon_label:"docs-and-forms"
                    },
                    {
                        label: 'Link sharing',
                        name: 'Link Sharing',
                        params: { client_id: this.$route.params.client_id },
                        // icon_label:"docs-and-forms"
                    },
                    {
                        label: 'Vitals',
                        name: 'Vitals',
                        params: { client_id: this.$route.params.client_id },
                        // icon_label:"docs-and-forms"
                    },
                ],
                sideBarTabpages: [
                    {
                        label: 'Medications',
                        name: 'Medications',
                        params: { client_id: this.$route.params.client_id },
                        // icon_label:"docs-and-forms"
                    },
                    {
                        label: 'Message',
                        name: 'Messaging',
                        params: { client_id: this.$route.params.client_id },
                        // icon_label:"docs-and-forms"
                    },
                ],
            };
        },
        computed: {
            clientId() {
                return this.$store.state.current_client_id;
                //return this.$store.state.clientData.
            },
            loggedInUser() {
                let localUserData = localStorage.getItem('cn-user');
                let userData = localUserData ? localUserData : undefined;
                return userData;
            },
        },
        methods: {
            goToPage(page) {
                this.$router.push(page);
            },
        },
        async created() {
            this.$forceUpdate(); //coming from other tab, you just created a new client, so history push is being funny.
        },
    };
</script>
